import React, { useState, useEffect } from "react";
import { Grid, Button, Alert, Form } from "tabler-react";
import moment from "moment";
import { QuestionnaireProps, ContentTextProps } from "../../types";
import ServerSidePagination from "../../components/pagination/serverSidePagination";
import TableCard from "../../components/tableCard";
import DatePicker from "react-datepicker";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { NOTIFICATIONS_CHART, GET_QUESTIONNAIRES } from "../../graphql/queries";
import Chart from "../../components/chart";
import { isProduction } from "../../helpers";

const questionnaireNames = {
  questionnaire1: "questionnaire1",
  questionnaire2: "questionnaire2",
  questionnaire3: "questionnaire3",
  questionnaire4: "questionnaire4",
  questionnaire5: "questionnaire5",
  questionnaire6: "questionnaire6",
  questionnaire7: "questionnaire7",
  questionnaire8: "questionnaire8",
  questionnaire9: "questionnaire9",
  questionnaire10: "questionnaire10",
  questionnaire11: "questionnaire11",
  questionnaire12: "questionnaire12",
  questionnaire13: "Hexomatic Appsumo popup",
  questionnaire14: "Extension popup",
  questionnaire15: "Last Call LTD - Hexomatic",
  questionnaire16: "Weekend premium credits promo - Hexomatic",
  questionnaire17: "Weekend premium credits promo - Hexowatch",

  questionnaire18: "Easter promo 2022 Hexowatch LTD",
  questionnaire19: "Easter promo 2022 Get one Hexowatch by one Hexometer",
  questionnaire20: "Easter promo 2022 Get one Hexomatic by one Hexometer",
  questionnaire21: "Easter promo 2022 Get one Hexomatic by one Hexowatch",
  questionnaire22: "April 2022 Hexomatic Premium credits promo",
  questionnaire23: "April 2022 Hexowatch Premium credits promo",
  questionnaire24: "Hexospark pre-launch",
  questionnaire25: "Hexomatic Premium credits Independence Day promo",
  questionnaire26: "Hexowatch Premium credits Independence Day promo",
  questionnaire27: "Hexometer Independence Day LTD promo",
  questionnaire28: "Hexowatch Independence Day LTD promo",
  questionnaire29: "Hexomatic Independence Day LTD promo",
  questionnaire30: "Hexomatic Halloween 2022 Premium Credits promo",
  questionnaire31: "Black Friday 2022 Tier plans (I) (early bird)",
  questionnaire32: "Black Friday 2022 Tier plans (II)",
  questionnaire33: "Black Friday 2022 Tier plans (III)",
  questionnaire34: "Holidays promo 2022 Hexomatic (I)",
  questionnaire35: "Holidays promo 2022 Hexowatch (I)",
  questionnaire36: "Holidays promo 2022 Hexometer (I)",
  questionnaire37: "Holidays promo 2022 Hexomatic (II)",
  questionnaire38: "Holidays promo 2022 Hexowatch (II)",
  questionnaire39: "Holidays promo 2022 Hexometer (II)",
  questionnaire40: "Holidays promo 2022 Hexospark (II)",
  questionnaire41: "Hexomatic Premium credits promo",
  questionnaire42: "Premium credits promo Hexowatch",
  questionnaire43: "Hexospark LTD January 2023",
  questionnaire44: "Hexomatic LTD January 2023 (small group)",
  questionnaire45: "Hexomatic LTD January 2023 (public)",
  questionnaire46: "Hexofy prelaunch",
  questionnaire47: "Hexomatic LTD February 2023 (small group)",
  questionnaire48: "Hexofy beta launch",
  questionnaire49: "Hexospark LTD March 2023",
  questionnaire50: "Hexomatic LTD April 2023 (ChatGPT)",
  questionnaire51: "Hexomatic Premium credits promo April 2023 (ChatGPT)",
  questionnaire52: "Hexospark Appsumo popup",
  questionnaire53: "Hexomatic Premium credits promo May 2023 (ChatGPT)",
  questionnaire54: "Hexofy LTD June 2023",
  questionnaire55: "Hexowatch LTD June 2023",
  questionnaire56: "Hexomatic onboarding video (on first login)",
  questionnaire57: "Hexomatic LTD June 2023",
  questionnaire58: "Hexomatic Premium credits promo August 2023",
  questionnaire59: "Hexomatic LTD September 2023",
  questionnaire60: "Hexomatic Premium credits 3 Year promo",
  questionnaire61: "Hexowatch LTD September 2023",
  questionnaire62: "Hexomatic LTD September/October 2023",
  questionnaire63: "Hexomatic Gold Special Promo October 2023",
  questionnaire64: "Hexomatic Halloween Promo 2023",
  questionnaire65: "Black Friday Early Bird 2023",
  questionnaire66: "Black Friday/Cyber Monday Deal 2023",
  questionnaire67: "Premium credits 3 years promo December 2023",
  questionnaire68: "Premium credits 3 years promo January/February 2024",
  questionnaire69: "Spring Break Everything Bundle 2024",
  questionnaire70: "Premium credits 3 years promo March 2024",
  questionnaire71: "Premium credits 3 years promo April 2024",
  questionnaire72: "Premium credits Annual promo May 2024",
  questionnaire73: "Premium credits 3 years promo June 2024 - First",
  questionnaire74: "Premium credits 3 years promo June 2024 - Second",
  questionnaire75: "Premium credits 3 years promo July 2024",
  questionnaire76: "Premium credits Annual promo August 2024",
  questionnaire77: "Premium credits Annual promo September 2024",
  questionnaire78: "Premium credits Annual promo April 2025",
};

const Questionnaires = ({ refetchData, ...props }: QuestionnaireProps): JSX.Element => {
  const [baseQuestionnaires, setBaseQuestionnaires] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [questionnairesPagination, setQuestionnairesPagination] = useState([]);
  const [total, setTotal] = useState(0);
  const [filterInput, setFilterInput] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [answer1, setAnswer1] = useState("all");
  const [answer2, setAnswer2] = useState("all");
  const [type, setType] = useState("all");
  const showButtons = localStorage.getItem("_rep");

  const [getCSV, { data, loading }] = useLazyQuery(GET_QUESTIONNAIRES);
  const [uploadToDrive, { data: driveData, loading: driveDataLoading }] = useLazyQuery(GET_QUESTIONNAIRES);

  const tableData = {
    tData:
      baseQuestionnaires &&
      baseQuestionnaires.map((item) => ({
        ID: item.id,
        Type: item.type,
        "Created At": moment(item.createdAt).utc().format("DD-MM-YYYY hh:mm:ss a"),
        Answer1: item.answer1,
        Answer2: item.answer2,
        User: item.user && item.user.email,
      })),
  };

  const questionnaires = {
    all: {
      answer1: [
        "all",
        "I am using Hexometer to monitor my or my company's website(s)",
        "I am using Hexometer to monitor my client’s website(s)",
        "I am using Hexometer to monitor my and my client’s website(s)",
        "followed",
        "cancelled",
        "empty",
      ],
      answer2: [
        "all",
        "A feature where you can receive quotes, from freelancers/agencies verified by Hexometer to fix the tasks/issues found on your website.",
        "A one-click solution - a fixed price per task button available from within Hexometer to fix issues on your website.",
        "A marketplace where you can provide your services to Hexometer's clients to fix issues found on a per task or hourly basis.",
        "I would prefer to solve issues myself (or via my own team).",
        "empty",
      ],
    },
    questionnaire78: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire77: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire76: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire75: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire74: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire73: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire72: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire71: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire70: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire69: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire68: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire67: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire66: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire65: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire64: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire63: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire62: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire61: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire60: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire59: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire58: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire57: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire56: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire55: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire54: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire53: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire52: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire51: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire50: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire49: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire48: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire47: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire46: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire45: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire44: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire43: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire42: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire41: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire40: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire39: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire38: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire37: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire36: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire35: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire34: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire33: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire32: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire31: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire30: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire29: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire28: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire27: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire26: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire25: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire24: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire23: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire22: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire21: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire20: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire19: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire18: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire17: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire16: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire15: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire14: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire13: {
      answer1: ["all", "clicked", "closed"],
      answer2: ["all"],
    },
    questionnaire12: { answer1: ["all"], answer2: ["all"] },
    questionnaire11: { answer1: ["all"], answer2: ["all"] },
    questionnaire10: { answer1: ["all"], answer2: ["all"] },
    questionnaire9: { answer1: ["all"], answer2: ["all"] },
    questionnaire8: { answer1: ["all"], answer2: ["all"] },
    questionnaire7: { answer1: ["all"], answer2: ["all"] },
    questionnaire6: { answer1: ["all"], answer2: ["all"] },
    questionnaire5: { answer1: ["all"], answer2: ["all"] },
    questionnaire4: { answer1: ["all"], answer2: ["all"] },
    questionnaire3: { answer1: ["all"], answer2: ["all"] },
    questionnaire2: {
      answer1: ["all", "followed", "cancelled"],
      answer2: [],
    },
    questionnaire1: {
      answer1: [
        "all",
        "I am using Hexometer to monitor my or my company's website(s)",
        "I am using Hexometer to monitor my client’s website(s)",
        "I am using Hexometer to monitor my and my client’s website(s)",
        "empty",
      ],
      answer2: [
        "all",
        "A feature where you can receive quotes, from freelancers/agencies verified by Hexometer to fix the tasks/issues found on your website.",
        "A one-click solution - a fixed price per task button available from within Hexometer to fix issues on your website.",
        "A marketplace where you can provide your services to Hexometer's clients to fix issues found on a per task or hourly basis.",
        "I would prefer to solve issues myself (or via my own team).",
        "empty",
      ],
    },
  };

  useEffect(() => {
    if (
      props.data &&
      props.data.Questionnaire &&
      props.data.Questionnaire.adminGetMany &&
      props.data.Questionnaire.adminGetMany.questionnaires
    ) {
      setDataLoading(props.loading);
      setBaseQuestionnaires(props.data.Questionnaire.adminGetMany.questionnaires);
      setQuestionnairesPagination(props.data.Questionnaire.adminGetMany.questionnaires);
      setTotal(props.data.Questionnaire.adminGetMany.total);
    }
  }, [props.data]);

  useEffect(() => {
    if (
      driveData &&
      driveData.Questionnaire &&
      driveData.Questionnaire.adminGetMany &&
      driveData.Questionnaire.adminGetMany.csvURL
    ) {
      window.open(
        isProduction()
          ? `https://api.hexometer.com/v2/app/social-callback/googleDrive?admin=admin&report=questionnaire&url=${driveData.Questionnaire.adminGetMany.csvURL}`
          : `http://localhost:4000/api/app/social-callback/googleDrive?admin=admin&report=questionnaire&url=${driveData.Questionnaire.adminGetMany.csvURL}`,
        "_blank"
      );
    }
  }, [driveData]);

  const handleInputChange = (e: React.FormEvent) => setFilterInput((e.target as HTMLInputElement).value);

  const handleTypeChange = (e: React.FormEvent) => setType((e.target as HTMLInputElement).value);

  const handleAnswer1Change = (e: React.FormEvent) => setAnswer1((e.target as HTMLInputElement).value);

  const handleAnswer2Change = (e: React.FormEvent) => setAnswer2((e.target as HTMLInputElement).value);

  const handleFromDate = (date: Date) => {
    if (date) {
      return setFromDate(moment(date).format("YYYY-MM-DDT00:00:00.000"));
    }
    return setFromDate(null);
  };

  const handleToDate = (date: Date) => {
    if (date) {
      return setToDate(moment(date).format("YYYY-MM-DDT23:59:59.999"));
    }
    return setToDate(null);
  };

  const handleSubmit = (
    event: React.FormEvent,
    input: string,
    ans1: string,
    ans2: string,
    startDate: string,
    endDate: string,
    type: string
  ) => {
    event.preventDefault();
    props.onFilterDataChange(input.trim());
    props.onFromDate(startDate);
    props.onToDate(endDate);
    if (type === "all") {
      props.onSelectType(undefined);
    } else {
      props.onSelectType(type);
    }
    if (ans1 === "all") {
      props.onSelectAnswer1(undefined);
    } else if (ans1 === "empty") {
      props.onSelectAnswer1("");
    } else {
      props.onSelectAnswer1(ans1);
    }
    if (ans2 === "all") {
      props.onSelectAnswer2(undefined);
    } else if (ans2 === "empty") {
      props.onSelectAnswer2("");
    } else {
      props.onSelectAnswer2(ans2);
    }
  };

  const handleSortingClick = (column: string) => {
    if (column === "Created At") {
      props.setOrderBy("createdAt");
      props.setOrderByASC(!props.orderByASC);
    }
  };

  return (
    <Grid>
      <Grid.Row>
        <div className="col-12 col-md-8"></div>
        <div className="col-12 col-md-4">
          <form onSubmit={(e) => handleSubmit(e, filterInput, answer1, answer2, fromDate, toDate, type)}>
            <Form.Group>
              <Form.Input
                label="Input"
                className="margin-bottom"
                icon="search"
                position="append"
                placeholder="Search for Code, User Email, User Name"
                value={filterInput}
                onChange={handleInputChange}
              />
              <Form.Select onChange={handleTypeChange} label="Type">
                {Object.keys(questionnaires).map((el) => (
                  <option key={el} value={el}>
                    {questionnaireNames[el]}
                  </option>
                ))}
              </Form.Select>
              <Form.Select onChange={handleAnswer1Change} label="Answer 1">
                {questionnaires[type === "" ? "all" : type].answer1 &&
                  questionnaires[type === "" ? "all" : type].answer1.map((el: string) => (
                    <option key={el} value={el}>
                      {el}
                    </option>
                  ))}
              </Form.Select>
              <Form.Select onChange={handleAnswer2Change} label="Answer 2">
                {questionnaires[type === "" ? "all" : type].answer2 &&
                  questionnaires[type === "" ? "all" : type].answer2.map((el: string) => (
                    <option key={el} value={el}>
                      {el}
                    </option>
                  ))}
              </Form.Select>
              <Form.Group label="From Date">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={fromDate ? new Date(fromDate) : null}
                  onChange={handleFromDate}
                  showMonthDropdown
                  placeholderText="Select a date"
                />
              </Form.Group>
              <Form.Group label="To Date">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={toDate ? new Date(toDate) : null}
                  onChange={handleToDate}
                  showMonthDropdown
                  placeholderText="Select a date"
                  minDate={fromDate && new Date(fromDate)}
                />
              </Form.Group>
              <Button className="margin-bottom" type="submit" icon="filter" color="primary">
                Filter
              </Button>
            </Form.Group>
          </form>
        </div>
      </Grid.Row>
      <Grid.Row className={showButtons === "true" ? "justify-content-between" : "justify-content-end"}>
        {showButtons === "true" && (
          <div className="col-12 col-md-3 col-xs-12">
            <Button.List>
              <Button
                onClick={() =>
                  getCSV({
                    variables: { ...props.variables, csvDownload: true },
                  })
                }
                loading={loading}
                color="primary"
                mr={1}
              >
                Get CSV Link
              </Button>
              <Button
                onClick={() =>
                  uploadToDrive({
                    variables: { ...props.variables, csvDownload: true },
                  })
                }
                loading={driveDataLoading}
                color="primary"
              >
                Save to Drive
              </Button>
            </Button.List>
            {!loading &&
              data &&
              data.Questionnaire &&
              data.Questionnaire.adminGetMany &&
              data.Questionnaire.adminGetMany.csvURL && (
                <Button RootComponent="a" href={data.Questionnaire.adminGetMany.csvURL} icon="download">
                  Download CSV
                </Button>
              )}
            <br />
          </div>
        )}
        <Grid.Col md={3}>
          <Alert type="info">Result Count - {total}</Alert>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row cards deck>
        <ServerSidePagination
          data={questionnairesPagination}
          total={total}
          onPageNum={props.onPageNumberChange}
        >
          <TableCard
            loading={dataLoading}
            data={
              baseQuestionnaires &&
              baseQuestionnaires.map((item: any) => {
                return { ...item, Type: questionnaireNames[item.Type] };
              })
            }
            tableData={
              tableData.tData && {
                tData: tableData.tData.map((item: any) => {
                  return { ...item, Type: questionnaireNames[item.Type] };
                }),
              }
            }
            onHeaderClick={handleSortingClick}
          />
        </ServerSidePagination>
      </Grid.Row>
      <Grid.Row className="justify-content-end">
        {total ? (
          <Grid.Col md={3}>
            <Alert type="info">Result Count - {total}</Alert>
          </Grid.Col>
        ) : null}
      </Grid.Row>
    </Grid>
  );
};

export default Questionnaires;
